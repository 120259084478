.hljs {
    background-color: #0D0D0D;
    border-radius: 0.5em;
    padding: 0;
    border: solid 1px rgb(42, 42, 42);
}

code {
    background-color: #0D0D0D;
    border: solid 1px rgb(42, 42, 42);
    font-family: "Courier New", monospace;
    font-size: 1em;
    padding: 0 0.4em 0 0.4em;
    color: #a37de4;
}