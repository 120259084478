.dropDownSectionContent {
    visibility: visible;
    overflow: hidden;
    max-height: 0;
    padding: 0;
    height: 0%;
    transition: max-height 0.4s ease,
                padding 0.4s ease;
    zoom: 0.9;
}

.dropDownSectionContent.active .dropDownSectionContent.active {
    margin-right: 0;
    padding-right: 0;
}

.dropDownSectionHeader {
    display: inline-flex;
    align-items: center;
    padding: 0.3em 0.3em 0.3em 1em;
    border: solid 1px rgb(42, 42, 42);
    width: 100%;
    height: auto;
}

.dropDownSection {
    margin-bottom: 1em;
    width: 100%;
    height: 100%;
}

.dropDownSection span {
    margin-right: 1em;
}

.dropDownSectionHeader h2 {
    justify-self: left;
    margin-right: 3em;
    margin: 0;
}

.dropDownSectionHeader i {
    font-size: 1em;
    margin-left: auto;
    margin-right: 1em;
    color: rgb(42, 42, 42);
}

.dropDownSectionContent.active {
    visibility: visible;
    overflow: hidden;
    font-size: 1em;
    padding: 1em 1em 2em 1.5em;
    height: 100%;
    max-height: 1000px;
    transition: max-height 0.4s ease,
                padding 0.4s ease;
    border-left: solid 1px rgb(42, 42, 42);
}

.dropDownSection.active {
    visibility: visible;
    width: 100%;
    height: 100%;
    max-height: none;
}

.dropDownSectionContent p {
    word-break: break-all;
    word-wrap: break-word;
}

.dropDownSectionContent.active p {
    word-break: break-all;
    word-wrap: break-word;
}

@media screen and (max-width: 738px) {
    .dropDownSectionHeader h2 span {
        overflow: visible;
        display: inline-block;
        justify-self: left;
        margin: 0;
        vertical-align: middle;
        width: 100%;
        font-size: 4vw;
    }

    .dropDownSectionHeader i {
        visibility: hidden;
        height: 0;
        width: 0;
    }

    .dropDownSectionContent.active {
        padding: 1em 0.5em 1em 1em;
    }

}