
/* Body and text */
@media screen and (max-width: 500px) {
  #sidebar {
    visibility: hidden;
    width: 0;
  }

  .wrapper {
    padding: 0;
    margin: 0;
  }

  #main {
    padding: 0;
    margin: 1em 1em 1em 1em;
  }

  #toggle_button {
    visibility: hidden;
    width: 0;
  }

}

@media screen and (max-width: 1680px) {
    body, input, select, textarea {
      font-size: 11pt;
    }
}

@media screen and (max-width: 1280px) {
    body, input, select, textarea {
      font-size: 10pt;
    }
}

@media screen and (max-width: 360px) {
    body, input, select, textarea {
      font-size: 9pt;
    }
}


/* Headers */
@media screen and (max-width: 1680px) {
    h1 {
        font-size: 3.5em;
    }
}

@media screen and (max-width: 980px) {
    h1 {
        font-size: 3.25em;
    }
}

@media screen and (max-width: 736px) {
    h1 {
        font-size: 2em;
        line-height: 1.4;
    }
    h2 {
        font-size: 1.5em;
    }
}

/* Basic */
@-ms-viewport {
    width: device-width;
}

@media screen and (max-width: 480px) {
    html, body {
        min-width: 320px;
    }
}

@media screen and (max-width: 1680px) {
    .row {
      display: flex;
      flex-wrap: wrap;
      box-sizing: border-box;
      align-items: stretch; }
      .row > * {
        box-sizing: border-box; }
      .row.gtr-uniform > * > :last-child {
        margin-bottom: 0; }
      .row.aln-left {
        justify-content: flex-start; }
      .row.aln-center {
        justify-content: center; }
      .row.aln-right {
        justify-content: flex-end; }
      .row.aln-top {
        align-items: flex-start; }
      .row.aln-middle {
        align-items: center; }
      .row.aln-bottom {
        align-items: flex-end; }
      .row > .imp-xlarge {
        order: -1; }
      .row > .col-1-xlarge {
        width: 8.33333%; }
      .row > .off-1-xlarge {
        margin-left: 8.33333%; }
      .row > .col-2-xlarge {
        width: 16.66667%; }
      .row > .off-2-xlarge {
        margin-left: 16.66667%; }
      .row > .col-3-xlarge {
        width: 25%; }
      .row > .off-3-xlarge {
        margin-left: 25%; }
      .row > .col-4-xlarge {
        width: 33.33333%; }
      .row > .off-4-xlarge {
        margin-left: 33.33333%; }
      .row > .col-5-xlarge {
        width: 41.66667%; }
      .row > .off-5-xlarge {
        margin-left: 41.66667%; }
      .row > .col-6-xlarge {
        width: 50%; }
      .row > .off-6-xlarge {
        margin-left: 50%; }
      .row > .col-7-xlarge {
        width: 58.33333%; }
      .row > .off-7-xlarge {
        margin-left: 58.33333%; }
      .row > .col-8-xlarge {
        width: 66.66667%; }
      .row > .off-8-xlarge {
        margin-left: 66.66667%; }
      .row > .col-9-xlarge {
        width: 75%; }
      .row > .off-9-xlarge {
        margin-left: 75%; }
      .row > .col-10-xlarge {
        width: 83.33333%; }
      .row > .off-10-xlarge {
        margin-left: 83.33333%; }
      .row > .col-11-xlarge {
        width: 91.66667%; }
      .row > .off-11-xlarge {
        margin-left: 91.66667%; }
      .row > .col-12-xlarge {
        width: 100%; }
      .row > .off-12-xlarge {
        margin-left: 100%; }
      .row.gtr-0 {
        margin-top: 0;
        margin-left: 0em; }
        .row.gtr-0 > * {
          padding: 0 0 0 0em; }
        .row.gtr-0.gtr-uniform {
          margin-top: 0em; }
          .row.gtr-0.gtr-uniform > * {
            padding-top: 0em; }
      .row.gtr-25 {
        margin-top: 0;
        margin-left: -0.375em; }
        .row.gtr-25 > * {
          padding: 0 0 0 0.375em; }
        .row.gtr-25.gtr-uniform {
          margin-top: -0.375em; }
          .row.gtr-25.gtr-uniform > * {
            padding-top: 0.375em; }
      .row.gtr-50 {
        margin-top: 0;
        margin-left: -0.75em; }
        .row.gtr-50 > * {
          padding: 0 0 0 0.75em; }
        .row.gtr-50.gtr-uniform {
          margin-top: -0.75em; }
          .row.gtr-50.gtr-uniform > * {
            padding-top: 0.75em; }
      .row {
        margin-top: 0;
        margin-left: -1.5em; }
        .row > * {
          padding: 0 0 0 1.5em; }
        .row.gtr-uniform {
          margin-top: -1.5em; }
          .row.gtr-uniform > * {
            padding-top: 1.5em; }
      .row.gtr-150 {
        margin-top: 0;
        margin-left: -2.25em; }
        .row.gtr-150 > * {
          padding: 0 0 0 2.25em; }
        .row.gtr-150.gtr-uniform {
          margin-top: -2.25em; }
          .row.gtr-150.gtr-uniform > * {
            padding-top: 2.25em; }
      .row.gtr-200 {
        margin-top: 0;
        margin-left: -3em; }
        .row.gtr-200 > * {
          padding: 0 0 0 3em; }
        .row.gtr-200.gtr-uniform {
          margin-top: -3em; }
          .row.gtr-200.gtr-uniform > * {
            padding-top: 3em; } }
  @media screen and (max-width: 1280px) {
    .row {
      display: flex;
      flex-wrap: wrap;
      box-sizing: border-box;
      align-items: stretch; }
      .row > * {
        box-sizing: border-box; }
      .row.gtr-uniform > * > :last-child {
        margin-bottom: 0; }
      .row.aln-left {
        justify-content: flex-start; }
      .row.aln-center {
        justify-content: center; }
      .row.aln-right {
        justify-content: flex-end; }
      .row.aln-top {
        align-items: flex-start; }
      .row.aln-middle {
        align-items: center; }
      .row.aln-bottom {
        align-items: flex-end; }
      .row > .imp-large {
        order: -1; }
      .row > .col-1-large {
        width: 8.33333%; }
      .row > .off-1-large {
        margin-left: 8.33333%; }
      .row > .col-2-large {
        width: 16.66667%; }
      .row > .off-2-large {
        margin-left: 16.66667%; }
      .row > .col-3-large {
        width: 25%; }
      .row > .off-3-large {
        margin-left: 25%; }
      .row > .col-4-large {
        width: 33.33333%; }
      .row > .off-4-large {
        margin-left: 33.33333%; }
      .row > .col-5-large {
        width: 41.66667%; }
      .row > .off-5-large {
        margin-left: 41.66667%; }
      .row > .col-6-large {
        width: 50%; }
      .row > .off-6-large {
        margin-left: 50%; }
      .row > .col-7-large {
        width: 58.33333%; }
      .row > .off-7-large {
        margin-left: 58.33333%; }
      .row > .col-8-large {
        width: 66.66667%; }
      .row > .off-8-large {
        margin-left: 66.66667%; }
      .row > .col-9-large {
        width: 75%; }
      .row > .off-9-large {
        margin-left: 75%; }
      .row > .col-10-large {
        width: 83.33333%; }
      .row > .off-10-large {
        margin-left: 83.33333%; }
      .row > .col-11-large {
        width: 91.66667%; }
      .row > .off-11-large {
        margin-left: 91.66667%; }
      .row > .col-12-large {
        width: 100%; }
      .row > .off-12-large {
        margin-left: 100%; }
      .row.gtr-0 {
        margin-top: 0;
        margin-left: 0em; }
        .row.gtr-0 > * {
          padding: 0 0 0 0em; }
        .row.gtr-0.gtr-uniform {
          margin-top: 0em; }
          .row.gtr-0.gtr-uniform > * {
            padding-top: 0em; }
      .row.gtr-25 {
        margin-top: 0;
        margin-left: -0.375em; }
        .row.gtr-25 > * {
          padding: 0 0 0 0.375em; }
        .row.gtr-25.gtr-uniform {
          margin-top: -0.375em; }
          .row.gtr-25.gtr-uniform > * {
            padding-top: 0.375em; }
      .row.gtr-50 {
        margin-top: 0;
        margin-left: -0.75em; }
        .row.gtr-50 > * {
          padding: 0 0 0 0.75em; }
        .row.gtr-50.gtr-uniform {
          margin-top: -0.75em; }
          .row.gtr-50.gtr-uniform > * {
            padding-top: 0.75em; }
      .row {
        margin-top: 0;
        margin-left: -1.5em; }
        .row > * {
          padding: 0 0 0 1.5em; }
        .row.gtr-uniform {
          margin-top: -1.5em; }
          .row.gtr-uniform > * {
            padding-top: 1.5em; }
      .row.gtr-150 {
        margin-top: 0;
        margin-left: -2.25em; }
        .row.gtr-150 > * {
          padding: 0 0 0 2.25em; }
        .row.gtr-150.gtr-uniform {
          margin-top: -2.25em; }
          .row.gtr-150.gtr-uniform > * {
            padding-top: 2.25em; }
      .row.gtr-200 {
        margin-top: 0;
        margin-left: -3em; }
        .row.gtr-200 > * {
          padding: 0 0 0 3em; }
        .row.gtr-200.gtr-uniform {
          margin-top: -3em; }
          .row.gtr-200.gtr-uniform > * {
            padding-top: 3em; } }
  @media screen and (max-width: 980px) {
    .row {
      display: flex;
      flex-wrap: wrap;
      box-sizing: border-box;
      align-items: stretch; }
      .row > * {
        box-sizing: border-box; }
      .row.gtr-uniform > * > :last-child {
        margin-bottom: 0; }
      .row.aln-left {
        justify-content: flex-start; }
      .row.aln-center {
        justify-content: center; }
      .row.aln-right {
        justify-content: flex-end; }
      .row.aln-top {
        align-items: flex-start; }
      .row.aln-middle {
        align-items: center; }
      .row.aln-bottom {
        align-items: flex-end; }
      .row > .imp-medium {
        order: -1; }
      .row > .col-1-medium {
        width: 8.33333%; }
      .row > .off-1-medium {
        margin-left: 8.33333%; }
      .row > .col-2-medium {
        width: 16.66667%; }
      .row > .off-2-medium {
        margin-left: 16.66667%; }
      .row > .col-3-medium {
        width: 25%; }
      .row > .off-3-medium {
        margin-left: 25%; }
      .row > .col-4-medium {
        width: 33.33333%; }
      .row > .off-4-medium {
        margin-left: 33.33333%; }
      .row > .col-5-medium {
        width: 41.66667%; }
      .row > .off-5-medium {
        margin-left: 41.66667%; }
      .row > .col-6-medium {
        width: 50%; }
      .row > .off-6-medium {
        margin-left: 50%; }
      .row > .col-7-medium {
        width: 58.33333%; }
      .row > .off-7-medium {
        margin-left: 58.33333%; }
      .row > .col-8-medium {
        width: 66.66667%; }
      .row > .off-8-medium {
        margin-left: 66.66667%; }
      .row > .col-9-medium {
        width: 75%; }
      .row > .off-9-medium {
        margin-left: 75%; }
      .row > .col-10-medium {
        width: 83.33333%; }
      .row > .off-10-medium {
        margin-left: 83.33333%; }
      .row > .col-11-medium {
        width: 91.66667%; }
      .row > .off-11-medium {
        margin-left: 91.66667%; }
      .row > .col-12-medium {
        width: 100%; }
      .row > .off-12-medium {
        margin-left: 100%; }
      .row.gtr-0 {
        margin-top: 0;
        margin-left: 0em; }
        .row.gtr-0 > * {
          padding: 0 0 0 0em; }
        .row.gtr-0.gtr-uniform {
          margin-top: 0em; }
          .row.gtr-0.gtr-uniform > * {
            padding-top: 0em; }
      .row.gtr-25 {
        margin-top: 0;
        margin-left: -0.375em; }
        .row.gtr-25 > * {
          padding: 0 0 0 0.375em; }
        .row.gtr-25.gtr-uniform {
          margin-top: -0.375em; }
          .row.gtr-25.gtr-uniform > * {
            padding-top: 0.375em; }
      .row.gtr-50 {
        margin-top: 0;
        margin-left: -0.75em; }
        .row.gtr-50 > * {
          padding: 0 0 0 0.75em; }
        .row.gtr-50.gtr-uniform {
          margin-top: -0.75em; }
          .row.gtr-50.gtr-uniform > * {
            padding-top: 0.75em; }
      .row {
        margin-top: 0;
        margin-left: -1.5em; }
        .row > * {
          padding: 0 0 0 1.5em; }
        .row.gtr-uniform {
          margin-top: -1.5em; }
          .row.gtr-uniform > * {
            padding-top: 1.5em; }
      .row.gtr-150 {
        margin-top: 0;
        margin-left: -2.25em; }
        .row.gtr-150 > * {
          padding: 0 0 0 2.25em; }
        .row.gtr-150.gtr-uniform {
          margin-top: -2.25em; }
          .row.gtr-150.gtr-uniform > * {
            padding-top: 2.25em; }
      .row.gtr-200 {
        margin-top: 0;
        margin-left: -3em; }
        .row.gtr-200 > * {
          padding: 0 0 0 3em; }
        .row.gtr-200.gtr-uniform {
          margin-top: -3em; }
          .row.gtr-200.gtr-uniform > * {
            padding-top: 3em; } }
  @media screen and (max-width: 736px) {
    .row {
      display: flex;
      flex-wrap: wrap;
      box-sizing: border-box;
      align-items: stretch; }
      .row > * {
        box-sizing: border-box; }
      .row.gtr-uniform > * > :last-child {
        margin-bottom: 0; }
      .row.aln-left {
        justify-content: flex-start; }
      .row.aln-center {
        justify-content: center; }
      .row.aln-right {
        justify-content: flex-end; }
      .row.aln-top {
        align-items: flex-start; }
      .row.aln-middle {
        align-items: center; }
      .row.aln-bottom {
        align-items: flex-end; }
      .row > .imp-small {
        order: -1; }
      .row > .col-1-small {
        width: 8.33333%; }
      .row > .off-1-small {
        margin-left: 8.33333%; }
      .row > .col-2-small {
        width: 16.66667%; }
      .row > .off-2-small {
        margin-left: 16.66667%; }
      .row > .col-3-small {
        width: 25%; }
      .row > .off-3-small {
        margin-left: 25%; }
      .row > .col-4-small {
        width: 33.33333%; }
      .row > .off-4-small {
        margin-left: 33.33333%; }
      .row > .col-5-small {
        width: 41.66667%; }
      .row > .off-5-small {
        margin-left: 41.66667%; }
      .row > .col-6-small {
        width: 50%; }
      .row > .off-6-small {
        margin-left: 50%; }
      .row > .col-7-small {
        width: 58.33333%; }
      .row > .off-7-small {
        margin-left: 58.33333%; }
      .row > .col-8-small {
        width: 66.66667%; }
      .row > .off-8-small {
        margin-left: 66.66667%; }
      .row > .col-9-small {
        width: 75%; }
      .row > .off-9-small {
        margin-left: 75%; }
      .row > .col-10-small {
        width: 83.33333%; }
      .row > .off-10-small {
        margin-left: 83.33333%; }
      .row > .col-11-small {
        width: 91.66667%; }
      .row > .off-11-small {
        margin-left: 91.66667%; }
      .row > .col-12-small {
        width: 100%; }
      .row > .off-12-small {
        margin-left: 100%; }
      .row.gtr-0 {
        margin-top: 0;
        margin-left: 0em; }
        .row.gtr-0 > * {
          padding: 0 0 0 0em; }
        .row.gtr-0.gtr-uniform {
          margin-top: 0em; }
          .row.gtr-0.gtr-uniform > * {
            padding-top: 0em; }
      .row.gtr-25 {
        margin-top: 0;
        margin-left: -0.375em; }
        .row.gtr-25 > * {
          padding: 0 0 0 0.375em; }
        .row.gtr-25.gtr-uniform {
          margin-top: -0.375em; }
          .row.gtr-25.gtr-uniform > * {
            padding-top: 0.375em; }
      .row.gtr-50 {
        margin-top: 0;
        margin-left: -0.75em; }
        .row.gtr-50 > * {
          padding: 0 0 0 0.75em; }
        .row.gtr-50.gtr-uniform {
          margin-top: -0.75em; }
          .row.gtr-50.gtr-uniform > * {
            padding-top: 0.75em; }
      .row {
        margin-top: 0;
        margin-left: -1.5em; }
        .row > * {
          padding: 0 0 0 1.5em; }
        .row.gtr-uniform {
          margin-top: -1.5em; }
          .row.gtr-uniform > * {
            padding-top: 1.5em; }
      .row.gtr-150 {
        margin-top: 0;
        margin-left: -2.25em; }
        .row.gtr-150 > * {
          padding: 0 0 0 2.25em; }
        .row.gtr-150.gtr-uniform {
          margin-top: -2.25em; }
          .row.gtr-150.gtr-uniform > * {
            padding-top: 2.25em; }
      .row.gtr-200 {
        margin-top: 0;
        margin-left: -3em; }
        .row.gtr-200 > * {
          padding: 0 0 0 3em; }
        .row.gtr-200.gtr-uniform {
          margin-top: -3em; }
          .row.gtr-200.gtr-uniform > * {
            padding-top: 3em; } }
  @media screen and (max-width: 480px) {
    .row {
      display: flex;
      flex-wrap: wrap;
      box-sizing: border-box;
      align-items: stretch; }
      .row > * {
        box-sizing: border-box; }
      .row.gtr-uniform > * > :last-child {
        margin-bottom: 0; }
      .row.aln-left {
        justify-content: flex-start; }
      .row.aln-center {
        justify-content: center; }
      .row.aln-right {
        justify-content: flex-end; }
      .row.aln-top {
        align-items: flex-start; }
      .row.aln-middle {
        align-items: center; }
      .row.aln-bottom {
        align-items: flex-end; }
      .row > .imp-xsmall {
        order: -1; }
      .row > .col-1-xsmall {
        width: 8.33333%; }
      .row > .off-1-xsmall {
        margin-left: 8.33333%; }
      .row > .col-2-xsmall {
        width: 16.66667%; }
      .row > .off-2-xsmall {
        margin-left: 16.66667%; }
      .row > .col-3-xsmall {
        width: 25%; }
      .row > .off-3-xsmall {
        margin-left: 25%; }
      .row > .col-4-xsmall {
        width: 33.33333%; }
      .row > .off-4-xsmall {
        margin-left: 33.33333%; }
      .row > .col-5-xsmall {
        width: 41.66667%; }
      .row > .off-5-xsmall {
        margin-left: 41.66667%; }
      .row > .col-6-xsmall {
        width: 50%; }
      .row > .off-6-xsmall {
        margin-left: 50%; }
      .row > .col-7-xsmall {
        width: 58.33333%; }
      .row > .off-7-xsmall {
        margin-left: 58.33333%; }
      .row > .col-8-xsmall {
        width: 66.66667%; }
      .row > .off-8-xsmall {
        margin-left: 66.66667%; }
      .row > .col-9-xsmall {
        width: 75%; }
      .row > .off-9-xsmall {
        margin-left: 75%; }
      .row > .col-10-xsmall {
        width: 83.33333%; }
      .row > .off-10-xsmall {
        margin-left: 83.33333%; }
      .row > .col-11-xsmall {
        width: 91.66667%; }
      .row > .off-11-xsmall {
        margin-left: 91.66667%; }
      .row > .col-12-xsmall {
        width: 100%; }
      .row > .off-12-xsmall {
        margin-left: 100%; }
      .row.gtr-0 {
        margin-top: 0;
        margin-left: 0em; }
        .row.gtr-0 > * {
          padding: 0 0 0 0em; }
        .row.gtr-0.gtr-uniform {
          margin-top: 0em; }
          .row.gtr-0.gtr-uniform > * {
            padding-top: 0em; }
      .row.gtr-25 {
        margin-top: 0;
        margin-left: -0.375em; }
        .row.gtr-25 > * {
          padding: 0 0 0 0.375em; }
        .row.gtr-25.gtr-uniform {
          margin-top: -0.375em; }
          .row.gtr-25.gtr-uniform > * {
            padding-top: 0.375em; }
      .row.gtr-50 {
        margin-top: 0;
        margin-left: -0.75em; }
        .row.gtr-50 > * {
          padding: 0 0 0 0.75em; }
        .row.gtr-50.gtr-uniform {
          margin-top: -0.75em; }
          .row.gtr-50.gtr-uniform > * {
            padding-top: 0.75em; }
      .row {
        margin-top: 0;
        margin-left: -1.5em; }
        .row > * {
          padding: 0 0 0 1.5em; }
        .row.gtr-uniform {
          margin-top: -1.5em; }
          .row.gtr-uniform > * {
            padding-top: 1.5em; }
      .row.gtr-150 {
        margin-top: 0;
        margin-left: -2.25em; }
        .row.gtr-150 > * {
          padding: 0 0 0 2.25em; }
        .row.gtr-150.gtr-uniform {
          margin-top: -2.25em; }
          .row.gtr-150.gtr-uniform > * {
            padding-top: 2.25em; }
      .row.gtr-200 {
        margin-top: 0;
        margin-left: -3em; }
        .row.gtr-200 > * {
          padding: 0 0 0 3em; }
        .row.gtr-200.gtr-uniform {
          margin-top: -3em; }
          .row.gtr-200.gtr-uniform > * {
            padding-top: 3em; } }

@media screen and (max-width: 480px) {
    ul.pagination li:nth-child(n+2):nth-last-child(n+2) {
        display: none; }
    ul.pagination li:first-child {
        padding-right: 0; } }

@media screen and (max-width: 980px) {
    .features {
        margin: 0 0 2em 0;
        width: 100%; }
        .features article {
        margin: 0 0 3em 0;
        width: 100%; }
        .features article:nth-child(2n - 1) {
            margin-right: 0; }
        .features article:nth-child(2n) {
            margin-left: 0; }
        .features article:nth-last-child(1), .features article:nth-last-child(2) {
            margin-bottom: 3em; }
        .features article:last-child {
            margin-bottom: 0; }
        .features article .icon {
            height: 8em;
            line-height: 8em;
            width: 8em; }
            .features article .icon:before {
            font-size: 2.25rem; }
            .features article .icon:after {
            height: 6em;
            margin: -3em 0 0 -3em;
            width: 6em; } }
    @media screen and (max-width: 480px) {
    .features article {
        -moz-flex-direction: column;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -moz-align-items: -moz-flex-start;
        -webkit-align-items: -webkit-flex-start;
        -ms-align-items: -ms-flex-start;
        align-items: flex-start; }
        .features article .icon {
        height: 6em;
        line-height: 6em;
        margin: 0 0 1.5em 0;
        width: 6em; }
        .features article .icon:before {
            font-size: 1.5rem; }
        .features article .icon:after {
            height: 4em;
            margin: -2em 0 0 -2em;
            width: 4em; } }
@media screen and (max-width: 480px) {
.features article .icon:before {
    font-size: 1.25rem; } }

@media screen and (min-width: 1681px) {
    .posts article:nth-child(3n + 1):before {
        display: none; }
    .posts article:nth-child(3n + 1):after {
        width: 100%; }
    .posts article:nth-last-child(1), .posts article:nth-last-child(2), .posts article:nth-last-child(3) {
        margin-bottom: 0; }
        .posts article:nth-last-child(1):before, .posts article:nth-last-child(2):before, .posts article:nth-last-child(3):before {
        height: 100%; }
        .posts article:nth-last-child(1):after, .posts article:nth-last-child(2):after, .posts article:nth-last-child(3):after {
        display: none; } }
    @media screen and (max-width: 1680px) {
    .posts article {
        width: calc(50% - 6em); }
        .posts article:nth-last-child(3) {
        margin-bottom: 6em; } }
    @media screen and (min-width: 481px) and (max-width: 1680px) {
    .posts article:nth-child(2n + 1):before {
        display: none; }
    .posts article:nth-child(2n + 1):after {
        width: 100%; }
    .posts article:nth-last-child(1), .posts article:nth-last-child(2) {
        margin-bottom: 0; }
        .posts article:nth-last-child(1):before, .posts article:nth-last-child(2):before {
        height: 100%; }
        .posts article:nth-last-child(1):after, .posts article:nth-last-child(2):after {
        display: none; } }
    @media screen and (max-width: 736px) {
    .posts {
        margin: 0 0 2em -4.5em;
        width: calc(100% + 4.5em); }
        .posts article {
        margin: 0 0 4.5em 4.5em;
        width: calc(50% - 4.5em); }
        .posts article:before {
            height: calc(100% + 4.5em);
            left: -2.25em; }
        .posts article:after {
            bottom: -2.25em;
            width: calc(100% + 4.5em); }
        .posts article:nth-last-child(3) {
            margin-bottom: 0em; }
          }
    @media screen and (max-width: 480px) {
    .posts {
        margin: 0 0 2em 0;
        width: 100%; }
        .posts article {
        margin: 0 0 4.5em 0;
        width: 100%; }
        .posts article:before {
            display: none; }
        .posts article:after {
            width: 100%; }
        .posts article:last-child {
            margin-bottom: 0; }
            .posts article:last-child:after {
            display: none; } }
    
/* Wrapper */
#wrapper {
    display: -moz-flex;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-direction: row-reverse;
    -webkit-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    min-height: 100vh; }
  
/* Main */
#main {
  -moz-flex-grow: 1;
  -webkit-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1;
  -moz-flex-shrink: 1;
  -webkit-flex-shrink: 1;
  -ms-flex-shrink: 1;
  flex-shrink: 1;
  width: 100%;
}

#main > .inner {
  padding: 0 6em 0.1em 6em ;
  margin: 0 auto;
  max-width: 110em;
}
#main > .inner > section {
  padding: 6em 0 4em 0 ;
  border-top: solid 2px rgba(210, 215, 217, 0.75);
}

#main > .inner > section:first-of-type {
  border-top: 0 !important;
}

@media screen and (min-width: 1681px) {
  #main {
    padding: 0 6em 0.1em 6em;
  }
}

@media screen and (max-width: 1680px) {
  #main > .inner {
    padding: 0 5em 0.1em 5em ;
  }
  #main > .inner > section {
    padding: 5em 0 3em 0 ;
  }
}

@media screen and (max-width: 1280px) {
  #main > .inner {
    padding: 0 4em 0.1em 4em;
  }
    #main > .inner > section {
      padding: 4em 0 2em 0 ;
  }
}

  /* Banner */
  #banner {
    padding: 6em 0 4em 0 ;
    display: -moz-flex;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex; }
    #banner h1 {
      margin-top: -0.125em; }
    #banner .content {
      -moz-flex-grow: 1;
      -webkit-flex-grow: 1;
      -ms-flex-grow: 1;
      flex-grow: 1;
      -moz-flex-shrink: 1;
      -webkit-flex-shrink: 1;
      -ms-flex-shrink: 1;
      flex-shrink: 1;
      width: 50%; }
    #banner .image {
      -moz-flex-grow: 0;
      -webkit-flex-grow: 0;
      -ms-flex-grow: 0;
      flex-grow: 0;
      -moz-flex-shrink: 0;
      -webkit-flex-shrink: 0;
      -ms-flex-shrink: 0;
      flex-shrink: 0;
      display: block;
      margin: 0 0 2em 4em;
      width: 50%; }
      #banner .image img {
        height: 100%;
        -moz-object-fit: cover;
        -webkit-object-fit: cover;
        -ms-object-fit: cover;
        object-fit: cover;
        -moz-object-position: center;
        -webkit-object-position: center;
        -ms-object-position: center;
        object-position: center;
        width: 100%; }
    @media screen and (orientation: portrait) {
      #banner {
        -moz-flex-direction: column-reverse;
        -webkit-flex-direction: column-reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse; }
        #banner h1 br {
          display: none; }
        #banner .content {
          -moz-flex-grow: 0;
          -webkit-flex-grow: 0;
          -ms-flex-grow: 0;
          flex-grow: 0;
          -moz-flex-shrink: 0;
          -webkit-flex-shrink: 0;
          -ms-flex-shrink: 0;
          flex-shrink: 0;
          width: 100%; }
        #banner .image {
          -moz-flex-grow: 0;
          -webkit-flex-grow: 0;
          -ms-flex-grow: 0;
          flex-grow: 0;
          -moz-flex-shrink: 0;
          -webkit-flex-shrink: 0;
          -ms-flex-shrink: 0;
          flex-shrink: 0;
          margin: 0 0 4em 0;
          height: 25em;
          max-height: 50vh;
          min-height: 18em;
          width: 100%; } }
    @media screen and (orientation: portrait) and (max-width: 480px) {
      #banner .image {
        max-height: 35vh; } }
  