.card {
    /* background-color: #0A0A0A; */
    border-radius: 8px;
    padding: 1em;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s ease-in-out;
}

.card:hover {
    transform: translateY(-5px);
}

.card-content {
    color: #ffffff;
    line-height: 1.5;
}