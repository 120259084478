#menu ul {
    color: #646464;
    font-weight: 600;
    font-size: 1.3em;
    letter-spacing: 0.05em;
    list-style: none;
    margin-bottom: 0;
    padding: 0;
}

#menu ul li, #menu ul span {
    border-bottom: 0;
    color: inherit;
    cursor: pointer;
    display: block;
    font-size: 0.9em;
    padding: 0.625em 0;
}

#menu ul li:hover, #menu ul span:hover {
    color: #888888;
}

#menu ul li span.opener {
    -moz-transition: color 0.2s ease-in-out, -moz-transform 0.2s ease-in-out;
    -webkit-transition: color 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
    -ms-transition: color 0.2s ease-in-out, -ms-transform 0.2s ease-in-out;
    transition: color 0.2s ease-in-out, transform 0.2s ease-in-out;
    text-decoration: none;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    position: relative;
}

#menu ul li span.opener:before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    text-transform: none !important;
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
}

#menu ul li span.opener:before, #menu ul span.opener:before {
    -moz-transition: color 0.2s ease-in-out, -moz-transform 0.2s ease-in-out;
    -webkit-transition: color 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
    -ms-transition: color 0.2s ease-in-out, -ms-transform 0.2s ease-in-out;
    transition: color 0.2s ease-in-out, transform 0.2s ease-in-out;
    color: #333333;
    content: '\f078';
    position: absolute;
    right: 0;
}

#menu ul li span.opener:hover:before {
    color: #8666bd;
}

#menu ul li span.opener.active:before {
    -moz-transform: rotate(-180deg);
    -webkit-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    transform: rotate(-180deg);
}

#menu ul li ul {
    overflow: hidden;
    transition: all 0.2s ease;
}

#menu ul li span.opener + ul {
    max-height: 0px;
    margin: 0;
    display: block;
}

#menu ul li span.opener.active + ul {
    max-height: 500px;
    margin-bottom: 1em;
    display: block;
}

#menu > ul > li {
    border-top: solid 1px rgba(210, 215, 217, 0.75);
    margin: 0.5em 0 0 0;
    padding: 0.5em 0 0 0;
}

#menu > ul > li > ul {
    color: #ffffff;
    display: none;
    margin: 0.5em 0 1.5em 0;
    padding-left: 1em;
}

#menu > ul > li > ul a, #menu > ul > li > ul span {
    font-size: 0.8em;
}

#menu > ul > li > ul > li {
    margin: 0.125em 0 0 0;
    padding: 0.125em 0 0 0;
}

#menu > ul > li:first-child {
    border-top: 0;
    margin-top: 0;
    padding-top: 0;
}
  
@media screen and (max-width: 738px) {
    #menu .major {
        padding: 0em;
        margin: 0em;
    }
}