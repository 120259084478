/* Banner */
#banner {
    padding: 3em 0 4em 0 ;
    display: -moz-flex;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
}

#banner .content {
    flex-grow: 1;
    flex-shrink: 1;
    width: 50%;
}

#banner_image_holder {
    transition: all 0.3s ease;
    overflow: hidden;
    position: relative;
    width: 20em;
}

#banner_image_holder_div {
    width: 20em;   
}

#banner_image {
    width: 100%;
    height: auto;
}

#banner_image_content {
    position: absolute;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.5);
    padding-left: 1em;
    padding-bottom: 1em;
    padding-top: 2em;
    width: 100%;
    bottom: 0em;
}

#banner_image_holder:hover {
    max-height: 100%;
    transform: scale(1.1);
    height: auto;
}

#banner_content {
    padding-left: 2em;
}

#banner {
    max-height: 700px;
}

@media screen and (max-width: 738px) {
    #banner {
        display: flex;
        flex-direction: column;
        visibility: visible;
        max-height: none;
    }

    #banner .content {
        padding-top: 1em;
        margin-top: 1em;
        margin-left: 1em;
        margin-right: 1em;
        width: 100%;
    }

    #banner_image {
        overflow: clip;
    }

    #banner_image_holder {
        align-items: center;
        overflow: hidden;
        padding: 1em 1em 1em 1em;
        width: 100%;
        height: auto;
    }

    #banner_image_holder:hover {} /* Do nothing */

    #banner_image_content {
        position: relative;
        width: 100%;
    }

    #banner .content {
        align-items: center;
        overflow: hidden;
        width: 100%;
        height: auto;
    }

    #banner_content.content {
        padding: 0em 2em 0em 2em;
        margin: 0;
        width: 100%;
    }
}