#toggle_button {
    outline: 0;
    position: fixed;
    transition: all 0.5s ease;
    border: 0;
    margin: auto;
    width: 3.9em;
    height: 3.9em;
}

#toggle_button:before {
    content: "\f0c9";
    display: inline-block;
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    font-size: 2rem;
    position: absolute;
    top: 0;
    left: 0.5em;
    width: inherit;
    height: inherit;
}
