@import url(fontawesome-all.min.css);
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,400italic,600italic|Roboto+Slab:400,700");

@import url(scrollbar.css);
@import url(card.css);
@import url(emphasis.css);
@import url(wrapper.css);
@import url(responsive.css);
@import url(Banner.css);
@import url(SideBarMobile.css);
@import url(features.css);
@import url(code.css);
@import url(DropDownSection.css);

/*
	Editorial by HTML5 UP
	html5up.net | @ajlkn
	Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
*/
html, body, div, span, applet, object,
iframe, h1, h2, h3, h4, h5, h6, p, blockquote,
pre, a, abbr, acronym, address, big, cite,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var, b,
u, i, center, dl, dt, dd, ol, ul, li, fieldset,
form, label, legend, table, caption, tbody,
tfoot, thead, tr, th, td, article, aside,
canvas, details, embed, figure, figcaption,
footer, header, hgroup, menu, nav, output, ruby,
section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

.content {
  padding-top: 1em;
  padding-left: 1em;
  padding-right: 1em;
  overflow-y: auto;
}

#main {
  background-color: inherit;
}

@media screen and (max-width: 738px) {
  #main {
    align-items: center;
    margin: 0.5em;
  }

}

.highlight {
  color:#8666bd;
}

.highlight:hover {
  color:rgb(213, 139, 255);
}
  
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }
  blockquote:before, blockquote:after, q:before, q:after {
    content: '';
    content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

body {
  -webkit-text-size-adjust: none; }

mark {
  background-color: transparent;
  color: inherit; }

input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input, select, textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none; }

body {
  -ms-overflow-style: scrollbar; }


html {
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit; }

body {
  background: #141414;
}

body.is-preload *, body.is-preload *:before, body.is-preload *:after, body.is-resizing *, body.is-resizing *:before, body.is-resizing *:after {
}

/* Type */
body, input, select, textarea {
  color: #ffffff;
  font-family: "Open Sans", sans-serif;
  font-size: 13pt;
  font-weight: 400;
  line-height: 1.65;
}

a {
    -moz-transition: color 0.2s ease-in-out, border-bottom-color 0.2s ease-in-out;
    -webkit-transition: color 0.2s ease-in-out, border-bottom-color 0.2s ease-in-out;
    -ms-transition: color 0.2s ease-in-out, border-bottom-color 0.2s ease-in-out;
    transition: color 0.2s ease-in-out, border-bottom-color 0.2s ease-in-out;
    border-bottom: dotted 1px;
    color: #ffffff;
    text-decoration: none;
    border: 0;
}

a:hover {
    border-bottom-color: #f56a6a;
    color: #8666bd !important;
}

a:hover strong {
    color: inherit;
}

strong, b {
  color: #8666bd;
  font-weight: 600;
}

em, i {
  font-style: italic; }

p {
  margin: 0 0 2em 0; }

h1, h2, h3, h4, h5, h6 {
  color: #ffffff;
  font-family: "Roboto Slab", serif;
  font-weight: 700;
  line-height: 1.5;
  margin: 0 0 1em 0; }
  h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
    color: inherit;
    text-decoration: none;
    border-bottom: 0; }

h1 {
  font-size: 4em;
  margin: 0 0 0.5em 0;
  line-height: 1.3;
}

@media screen and (max-width: 738px) {
  h1 {
    font-size: 3em;
  }
}

h2 {
  font-size: 1.75em; }

h3 {
  font-size: 1.25em; }

h4 {
  font-size: 1.1em; }

h5 {
  font-size: 0.9em; }

h6 {
  font-size: 0.7em; }


sub {
  font-size: 0.8em;
  position: relative;
  top: 0.5em; }

sup {
  font-size: 0.8em;
  position: relative;
  top: -0.5em; }

blockquote {
  border-left: solid 3px rgba(210, 215, 217, 0.75);
  font-style: italic;
  margin: 0 0 2em 0;
  padding: 0.5em 0 0.5em 2em; }

pre {
  -webkit-overflow-scrolling: touch;
  font-family: "Courier New", monospace;
  font-size: 0.9em;
  margin: 0 0 2em 0; }

hr {
  border: 0;
  border-bottom: solid 1px rgba(210, 215, 217, 0.75);
  margin: 2em 0; }
  hr.major {
    margin: 3em 0; }

.align-left {
  text-align: left; }

.align-center {
  text-align: center; }

.align-right {
  text-align: right; }


/* Section/Article */
section.special, article.special {
  text-align: center; }

header p {
  font-family: "Roboto Slab", serif;
  font-size: 1em;
  font-weight: 400;
  letter-spacing: 0.075em;
  margin-top: -0.5em;
}

header.major > :last-child {
  border-bottom: solid 3px #452678;
  display: inline-block;
}

header.main > :last-child {
  margin: 0 0 1em 0;
}

/* Form */
form {
  margin: 0 0 2em 0; }

label {
  color: #3d4449;
  display: block;
  font-size: 0.9em;
  font-weight: 600;
  margin: 0 0 1em 0; }

input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="search"],
input[type="url"],
select,
textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background: #1c1c1c;
  border-radius: 0.375em;
  border: none;
  border: solid 1px rgba(210, 215, 217, 0.75);
  color: inherit;
  display: block;
  outline: 0;
  padding: 0 1em;
  text-decoration: none;
  width: 100%; }
  input[type="text"]:invalid,
  input[type="password"]:invalid,
  input[type="email"]:invalid,
  input[type="tel"]:invalid,
  input[type="search"]:invalid,
  input[type="url"]:invalid,
  select:invalid,
  textarea:invalid {
    box-shadow: none; }
  input[type="text"]:focus,
  input[type="password"]:focus,
  input[type="email"]:focus,
  input[type="tel"]:focus,
  input[type="search"]:focus,
  input[type="url"]:focus,
  select:focus,
  textarea:focus {
    border-color: #610094;
    box-shadow: 0 0 0 1px #1c1c1c; }

select {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' preserveAspectRatio='none' viewBox='0 0 40 40'%3E%3Cpath d='M9.4,12.3l10.4,10.4l10.4-10.4c0.2-0.2,0.5-0.4,0.9-0.4c0.3,0,0.6,0.1,0.9,0.4l3.3,3.3c0.2,0.2,0.4,0.5,0.4,0.9 c0,0.4-0.1,0.6-0.4,0.9L20.7,31.9c-0.2,0.2-0.5,0.4-0.9,0.4c-0.3,0-0.6-0.1-0.9-0.4L4.3,17.3c-0.2-0.2-0.4-0.5-0.4-0.9 c0-0.4,0.1-0.6,0.4-0.9l3.3-3.3c0.2-0.2,0.5-0.4,0.9-0.4S9.1,12.1,9.4,12.3z' fill='rgba(210, 215, 217, 0.75)' /%3E%3C/svg%3E");
  background-size: 1.25em;
  background-repeat: no-repeat;
  background-position: calc(100% - 1em) center;
  height: 2.75em;
  padding-right: 2.75em;
  text-overflow: ellipsis; }
  select option {
    color: #3d4449;
    background: #ffffff; }
  select:focus::-ms-value {
    background-color: transparent; }
  select::-ms-expand {
    display: none; }

input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="search"],
input[type="url"],
select {
  height: 2.75em; }

textarea {
  padding: 0.75em 1em; }

input[type="checkbox"],
input[type="radio"] {
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  display: block;
  float: left;
  margin-right: -2em;
  opacity: 0;
  width: 1em;
  z-index: -1; }
  input[type="checkbox"] + label,
  input[type="radio"] + label {
    text-decoration: none;
    color: #7f888f;
    cursor: pointer;
    display: inline-block;
    font-size: 1em;
    font-weight: 400;
    padding-left: 2.4em;
    padding-right: 0.75em;
    position: relative; }
    input[type="checkbox"] + label:before,
    input[type="radio"] + label:before {
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      line-height: 1;
      text-transform: none !important;
      font-family: 'Font Awesome 5 Free';
      font-weight: 900; }
    input[type="checkbox"] + label:before,
    input[type="radio"] + label:before {
      background: #ffffff;
      border-radius: 0.375em;
      border: solid 1px rgba(210, 215, 217, 0.75);
      content: '';
      display: inline-block;
      font-size: 0.8em;
      height: 2.0625em;
      left: 0;
      line-height: 2.0625em;
      position: absolute;
      text-align: center;
      top: 0;
      width: 2.0625em; }
  input[type="checkbox"]:checked + label:before,
  input[type="radio"]:checked + label:before {
    background: #3d4449;
    border-color: #3d4449;
    color: #ffffff;
    content: '\f00c'; }
  input[type="checkbox"]:focus + label:before,
  input[type="radio"]:focus + label:before {
    border-color: #f56a6a;
    box-shadow: 0 0 0 1px #f56a6a; }

input[type="checkbox"] + label:before {
  border-radius: 0.375em; }

input[type="radio"] + label:before {
  border-radius: 100%; }

::-webkit-input-placeholder {
  color: #9fa3a6 !important;
  opacity: 1.0; }

:-moz-placeholder {
  color: #9fa3a6 !important;
  opacity: 1.0; }

::-moz-placeholder {
  color: #9fa3a6 !important;
  opacity: 1.0; }

:-ms-input-placeholder {
  color: #9fa3a6 !important;
  opacity: 1.0; }

/* Box */
.box {
  border-radius: 0.375em;
  border: solid 1px rgba(210, 215, 217, 0.75);
  margin-bottom: 2em;
  padding: 1.5em; }
  .box > :last-child,
  .box > :last-child > :last-child,
  .box > :last-child > :last-child > :last-child {
    margin-bottom: 0; }
  .box.alt {
    border: 0;
    border-radius: 0;
    padding: 0; }

/* Icon */
.icon {
  text-decoration: none;
  border-bottom: none;
  position: relative; }
  .icon:before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    text-transform: none !important;
    font-family: 'Font Awesome 5 Free';
    font-weight: 400; }
  .icon > .label {
    display: none; }
  .icon:before {
    line-height: inherit; }
  .icon.solid:before {
    font-weight: 900; }
  .icon.brands:before {
    font-family: 'Font Awesome 5 Brands'; }

/* Image */
.image {
  border-radius: 0.375em;
  border: 0;
  display: inline-block;
  position: relative; }
  .image img {
    border-radius: 0.375em;
    display: block; }
  .image.left, .image.right {
    max-width: 40%; }
    .image.left img, .image.right img {
      width: 100%; }
  .image.left {
    float: left;
    padding: 0 1.5em 1em 0;
    top: 0.25em; }
  .image.right {
    float: right;
    padding: 0 0 1em 1.5em;
    top: 0.25em; }
  .image.fit {
    display: block;
    margin: 0 0 2em 0;
    width: 100%; }
    .image.fit img {
      width: 100%; }
  .image.main {
    display: block;
    margin: 0 0 3em 0;
    width: 100%; }
    .image.main img {
      width: 100%; }

a.image {
  overflow: hidden; }
  a.image img {
    -moz-transition: -moz-transform 0.2s ease;
    -webkit-transition: -webkit-transform 0.2s ease;
    -ms-transition: -ms-transform 0.2s ease;
    transition: transform 0.2s ease; }
  a.image:hover img {
    -moz-transform: scale(1.075);
    -webkit-transform: scale(1.075);
    -ms-transform: scale(1.075);
    transform: scale(1.075); }

/* List */
ol {
  list-style: decimal;
  margin: 0 0 2em 0;
  padding-left: 1.25em; }
  ol li {
    padding-left: 0.25em; }

ul {
  list-style: disc;
  margin: 0 0 2em 0;
  padding-left: 1em; }
  ul li {
    padding-left: 0.5em; }
  ul.alt {
    list-style: none;
    padding-left: 0; }
    ul.alt li {
      border-top: solid 1px rgba(210, 215, 217, 0.75);
      padding: 0.5em 0; }
      ul.alt li:first-child {
        border-top: 0;
        padding-top: 0; }

dl {
  margin: 0 0 2em 0; }
  dl dt {
    display: block;
    font-weight: 600;
    margin: 0 0 1em 0; }
  dl dd {
    margin-left: 2em; }

/* Actions */
ul.actions {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  cursor: default;
  list-style: none;
  margin-left: -1em;
  padding-left: 0; }
  ul.actions li {
    padding: 0 0 0 1em;
    vertical-align: middle; }
  ul.actions.special {
    -moz-justify-content: center;
    -webkit-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    width: 100%;
    margin-left: 0; }
    ul.actions.special li:first-child {
      padding-left: 0; }
  ul.actions.stacked {
    -moz-flex-direction: column;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-left: 0; }
    ul.actions.stacked li {
      padding: 1.3em 0 0 0; }
      ul.actions.stacked li:first-child {
        padding-top: 0; }
  ul.actions.fit {
    width: calc(100% + 1em); }
    ul.actions.fit li {
      -moz-flex-grow: 1;
      -webkit-flex-grow: 1;
      -ms-flex-grow: 1;
      flex-grow: 1;
      -moz-flex-shrink: 1;
      -webkit-flex-shrink: 1;
      -ms-flex-shrink: 1;
      flex-shrink: 1;
      width: 100%; }
      ul.actions.fit li > * {
        width: 100%; }
    ul.actions.fit.stacked {
      width: 100%; }


/* Contact */
ul.contact {
  list-style: none;
  padding: 0; }
  ul.contact li {
    text-decoration: none;
    border-top: solid 1px rgba(210, 215, 217, 0.75);
    margin: 1.5em 0 0 0;
    padding: 1.5em 0 0 3em;
    position: relative; }
    ul.contact li:before {
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      line-height: 1;
      text-transform: none !important;
      font-family: 'Font Awesome 5 Free';
      font-weight: 400; }
    ul.contact li:before {
      color: #8666bd;
      display: inline-block;
      font-size: 1.5em;
      height: 1.125em;
      left: 0;
      line-height: 1.125em;
      position: absolute;
      text-align: center;
      top: 1em;
      width: 1.5em; }
    ul.contact li:first-child {
      border-top: 0;
      margin-top: 0;
      padding-top: 0; }
      ul.contact li:first-child:before {
        top: 0; }
    ul.contact li a {
      color: inherit; }

/* Pagination */
ul.pagination {
  cursor: default;
  list-style: none;
  padding-left: 0; }
  ul.pagination li {
    display: inline-block;
    padding-left: 0;
    vertical-align: middle; }
    ul.pagination li > .page {
      -moz-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
      -webkit-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
      -ms-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
      transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
      border-bottom: 0;
      border-radius: 0.375em;
      display: inline-block;
      font-size: 0.8em;
      font-weight: 600;
      height: 2em;
      line-height: 2em;
      margin: 0 0.125em;
      min-width: 2em;
      padding: 0 0.5em;
      text-align: center; }
      ul.pagination li > .page.active {
        background-color: #610094;
        color: #ffffff !important; }
        ul.pagination li > .page.active:hover {
          background-color: #3F0071; }
        ul.pagination li > .page.active:active {
          background-color: #3F0071; }
    ul.pagination li:first-child {
      padding-right: 0.75em; }
    ul.pagination li:last-child {
      padding-left: 0.75em; }
  @media screen and (max-width: 480px) {
    ul.pagination li:nth-child(n+2):nth-last-child(n+2) {
      display: none; }
    ul.pagination li:first-child {
      padding-right: 0; } }

/* Table */
.table-wrapper {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto; }

table {
  margin: 0 0 2em 0;
  width: 100%; }
  table tbody tr {
    border: solid 1px rgba(210, 215, 217, 0.75);
    border-left: 0;
    border-right: 0; }
    table tbody tr:nth-child(2n + 1) {
      background-color: rgba(230, 235, 237, 0.25); }
  table td {
    padding: 0.75em 0.75em; }
  table th {
    color: #3d4449;
    font-size: 0.9em;
    font-weight: 600;
    padding: 0 0.75em 0.75em 0.75em;
    text-align: left; }
  table thead {
    border-bottom: solid 2px rgba(210, 215, 217, 0.75); }
  table tfoot {
    border-top: solid 2px rgba(210, 215, 217, 0.75); }
  table.alt {
    border-collapse: separate; }
    table.alt tbody tr td {
      border: solid 1px rgba(210, 215, 217, 0.75);
      border-left-width: 0;
      border-top-width: 0; }
      table.alt tbody tr td:first-child {
        border-left-width: 1px; }
    table.alt tbody tr:first-child td {
      border-top-width: 1px; }
    table.alt thead {
      border-bottom: 0; }
    table.alt tfoot {
      border-top: 0; }

/* Button */
input[type="submit"],
input[type="reset"],
input[type="button"],
button,
.button {
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  -moz-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  -ms-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  background-color: transparent;
  border-radius: 0.375em;
  border: 0;
  box-shadow: inset 0 0 0 2px #8666bd;
  color: #8666bd !important;
  cursor: pointer;
  display: inline-block;
  font-family: "Roboto Slab", serif;
  font-size: 0.8em;
  font-weight: 700;
  height: 3.5em;
  letter-spacing: 0.075em;
  line-height: 3.5em;
  padding: 0 2.25em;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap; }
  input[type="submit"]:hover,
  input[type="reset"]:hover,
  input[type="button"]:hover,
  button:hover,
  .button:hover {
    background-color: rgba(245, 106, 106, 0.05); }
  input[type="submit"]:active,
  input[type="reset"]:active,
  input[type="button"]:active,
  button:active,
  .button:active {
    background-color: rgba(245, 106, 106, 0.15); }
  input[type="submit"].icon:before,
  input[type="reset"].icon:before,
  input[type="button"].icon:before,
  button.icon:before,
  .button.icon:before {
    margin-right: 0.5em; }
  input[type="submit"].fit,
  input[type="reset"].fit,
  input[type="button"].fit,
  button.fit,
  .button.fit {
    width: 100%; }
  input[type="submit"].small,
  input[type="reset"].small,
  input[type="button"].small,
  button.small,
  .button.small {
    font-size: 0.6em; }
  input[type="submit"].large,
  input[type="reset"].large,
  input[type="button"].large,
  button.large,
  .button.large {
    font-size: 1em;
    height: 3.65em;
    line-height: 3.65em; }
  input[type="submit"].primary,
  input[type="reset"].primary,
  input[type="button"].primary,
  button.primary,
  .button.primary {
    background-color: #f56a6a;
    box-shadow: none;
    color: #ffffff !important; }
    input[type="submit"].primary:hover,
    input[type="reset"].primary:hover,
    input[type="button"].primary:hover,
    button.primary:hover,
    .button.primary:hover {
      background-color: #f67878; }
    input[type="submit"].primary:active,
    input[type="reset"].primary:active,
    input[type="button"].primary:active,
    button.primary:active,
    .button.primary:active {
      background-color: #f45c5c; }
  input[type="submit"].disabled, input[type="submit"]:disabled,
  input[type="reset"].disabled,
  input[type="reset"]:disabled,
  input[type="button"].disabled,
  input[type="button"]:disabled,
  button.disabled,
  button:disabled,
  .button.disabled,
  .button:disabled {
    pointer-events: none;
    opacity: 0.25; }

/* Mini Posts */
.mini-posts article {
  border-top: solid 1px rgba(210, 215, 217, 0.75);
  margin-top: 2em;
  padding-top: 2em; }
  .mini-posts article .image {
    display: block;
    margin: 0 0 1.5em 0; }
    .mini-posts article .image img {
      display: block;
      width: 100%; }
  .mini-posts article:first-child {
    border-top: 0;
    margin-top: 0;
    padding-top: 0; }

/* Posts */
.posts {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 0 2em -6em;
    width: calc(100% + 6em);
  }

.posts article {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  margin: 0 0 6em 6em;
  width: calc(33.33333% - 6em);
  margin-bottom: 1em;
}

.posts article .image {
    display: block;
    margin: 0 0 1em 0;
}

.posts article .image img {
    display: block;
    width: 100%;
}

@media screen and (max-width: 738px) {
  .posts {
    display: flex;
    flex-direction: column;
    margin: 0 0 1em 0em;
    width: 100%;
  }

  .posts article {
    width: 100%;
    margin: 0em;
    padding: 0em;
  }
}

/* Footer */
#footer .copyright {
  color: #9fa3a6;
  font-size: 0.9em;
}