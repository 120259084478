.pricing {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
}

.pricinglist {
    font-size: 1rem;
    list-style-type: none;
    margin-left: 0;
    padding: 0;
    font-size: 1.2em;
}

.pricingtag {
    margin: 0em 1em 1em 0em;
    padding: 1em;
}

.pricelinebig {
    font-size: 2em;
}

.pricelinesmall {
    font-size: 1.5em;
}