#sidebar {
    -moz-flex-grow: 0;
    -webkit-flex-grow: 0;
    -ms-flex-grow: 0;
    flex-grow: 0;
    -moz-flex-shrink: 0;
    -webkit-flex-shrink: 0;
    -ms-flex-shrink: 0;
    flex-shrink: 0;
    background-color: #0e0e0e;
    font-size: 0.9em;
    position: relative;
    width: 26em;
    transition: margin-left 0.5s ease;
}


#sidebar h2 {
    font-size: 1.38889em;
}

#sidebar > .inner {
    padding: 2.22222em 2.22222em 2.44444em 2.22222em;
    position: relative;
    width: 26em;
}

#sidebar > .inner > * {
    border-bottom: solid 2px rgb(77, 77, 77);
    margin: 0 0 3.5em 0;
    padding: 0 0 3.5em 0;
}

#sidebar > .inner > * > :last-child {
    margin-bottom: 0;
}

#sidebar > .inner > *:last-child {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0;
}

#sidebar > .inner > .alt {
    background-color: #121212;
    border-bottom: 0;
    margin: -2.22222em 0 4.44444em -2.22222em;
    padding: 2.22222em;
    width: calc(100% + 4.44444em);
}

#sidebar .toggle {
    text-decoration: none;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    border: 0;
    display: block;
    height: 7.5em;
    left: 26em;
    line-height: 7.5em;
    outline: 0;
    overflow: hidden;
    position: absolute;
    text-align: center;
    text-indent: -15em;
    white-space: nowrap;
    top: 0;
    width: 6em;
    z-index: 10000;
}

#sidebar .toggle:before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    text-transform: none !important;
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
}

#sidebar .toggle:before {
    content: '\f0c9';
    font-size: 2rem;
    height: inherit;
    left: 0;
    line-height: inherit;
    position: absolute;
    text-indent: 0;
    top: 0;
    width: inherit;
}